import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container } from '../components/Grid';

const IndexPage = ({ data }) => (
  <Layout title="Page Not Found">
    <Section>
      <Container>
        <p>The page you were looking for could not be found.</p>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
